import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
//import Heading from 'common/src/components/Heading';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, { List, ListItem, SocialList } from './footer.style';

import LogoImage from 'common/src/assets/image/archerhealth/footer/Logo-Archer-Health-Footer.png';

import { Icon } from 'react-icons-kit';
//import { facebook } from 'react-icons-kit/fa/facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare';
//import { skype } from 'react-icons-kit/fa/skype';
import { twitter } from 'react-icons-kit/fa/twitter';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  //titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        }        
      }
    }
  `);

  return (
    <FooterWrapper id="footerSection">
      <Container>
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="#"
              logoSrc={LogoImage}
              title="Agency"
              logoStyle={logoStyle}
            />
            <Text content="hello@archerhealth.com" {...textStyle} />
            <Text content="601-565-0075" {...textStyle} />
            <SocialList>                                              
                    <li>
                      <a href="https://www.instagram.com/archer_health/">
                        <Icon icon={instagram} />
                      </a>
                    </li>  
                    <li>
                      <a href="https://www.linkedin.com/company/archer-health/">
                        <Icon icon={linkedinSquare} />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/health_archer">
                        <Icon icon={twitter} />
                      </a>
                    </li>  
                
            </SocialList>
            <Text content="©2020 Archer Health. All Rights Reserved." className="footer-copyright"/>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.agencyJson.menuWidget.map(widget => (
              <Box className="col" {...col} key={widget.id}>
                {/* <Heading content={widget.title} {...titleStyle} /> */}
                <List>
                  {widget.menuItems.map(item => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a className="ListItem" href={item.url}>
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    //width: ['100%', '30%', '35%', '30%'],
    width: ['100%','50%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    //width: ['100%', '70%', '65%', '70%'],
    width: ['100%','50%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    //width: ['100%', '50%', '50%', '25%'],
    width: ['100%'],
    pl: '45px',
    pr: '45px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#ffffff',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
