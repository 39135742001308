import styled from 'styled-components';
import { themeGet } from 'styled-system';
import FooterImage from 'common/src/assets/image/archerhealth/footer/Logo-Archer-Health-Footer.png';

const FooterWrapper = styled.section`
  padding: 80px 0 20px 0;
  //margin-top: 40px;
  //Archer Health Custom
  //background-image: url(${FooterImage});
  //background-repeat: no-repeat;
  //background-position: center 50px;
  background: #294B53;
  //border-top: 1px solid #efefef;
  overflow: hidden;
  @media (max-width: 990px) {
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }
  p, a {
    color: ${themeGet('colors.footerTextColor', 'rgba(52, 61, 72, 0.8)')};
  }

  p.footer-copyright {
    color: #9CBAC1;
    font-size: 14px;
    margin-top: 100px;
    @media (max-width: 480px) {
      margin-top: 25px;
    }
  }
`;

const List = styled.ul`
  @media (min-width: 990px) {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

const ListItem = styled.li`
  a {
    color: ${themeGet('colors.footerTextColor', 'rgba(52, 61, 72, 0.8)')};
    font-size: 14px;
    line-height: 25px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet('colors.quoteText', '#343d48')};
    }
  }
`;

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 30px;

  li {
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet('colors.lightText', '#ffffff')};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 15px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet('colors.primary', '#FDEF00')};
      }
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
